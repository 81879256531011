<template>
  <DashboardAdminTemplate>
    <div class="pd-x-5">
      <div class="is-flex ai-center">
        <el-form :model="form" label-position="top" style="width: 75%">
          <h2>ทดสอบการร้องขอข้อมูลจาก DBD Web services</h2>
          <el-form-item label="เลขนิติบุคคล" prop="juristicId">
            <div class="is-flex">
              <el-input
                v-model="form.juristicId"
                maxlength="13"
                v-mask="'#############'"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="ประเภทข้อมูล" prop="option">
            <el-select
              v-model="form.option"
              placeholder="เลือกประเภทข้อมูล"
              class="w-100"
            >
              <el-option label="Both" value="both"
                ><div class="text-over">Both</div></el-option
              >
              <el-option label="Company" value="company"
                ><div class="text-over">Company (GetJuristic)</div></el-option
              >
              <el-option label="Finance" value="finance"
                ><div class="text-over">Finance (GetRangeYear)</div></el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item class="text-right">
            <div class="is-flex js-between ai-center mg-t-1">
              <div>
                <el-button
                  type="primary"
                  @click="checkCompany"
                  v-loading="loading"
                  >ยืนยัน</el-button
                >
              </div>
            </div>
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Response Juristic: ">
                <pre
                  style="
                    border: 1px solid #ccc;
                    padding: 10px;
                    border-radius: 3px;
                    line-height: 20px;
                  "
                  >{{ responseCompany }}</pre
                >
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Response Finance: ">
                <pre
                  style="
                    border: 1px solid #ccc;
                    padding: 10px;
                    border-radius: 3px;
                    line-height: 20px;
                  "
                  >{{ responseFinance }}</pre
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </DashboardAdminTemplate>
</template>
<script>
import DashboardAdminTemplate from "@/template/DashboardAdminTemplate";
import { HTTP } from "@/service/axios";

export default {
  components: {
    DashboardAdminTemplate
  },
  data() {
    return {
      loading: false,
      form: {
        juristicId: "0107535000079",
        dev: false,
        option: "both"
      },
      responseCompany: "No Data",
      responseFinance: "No Data"
    };
  },
  methods: {
    async checkCompany() {
      this.loading = true;
      // console.log("this.form", this.form);
      if (this.form.option == "both") {
        await HTTP.post("service/dbd", {
          juristicId: this.form.juristicId,
          dev: this.form.dev,
          option: "company"
        }).then(async res => {
          this.responseCompany = res.data;
        });

        await HTTP.post("service/dbd", {
          juristicId: this.form.juristicId,
          dev: this.form.dev,
          option: "finance"
        })
          .then(async res => {
            this.responseFinance = res.data;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        await HTTP.post("service/dbd", this.form)
          .then(async res => {
            // console.log(res);
            if (this.form.option == "company") {
              this.responseCompany = res.data;
            } else if (this.form.option == "finance") {
              this.responseFinance = res.data;
            }
            //   if (res.data.IsSuccess) {
            //     this.response = res.data;
            //   } else {
            //     this.alertCatchError(res.data.Description);
            //     this.$alert(res.data.Description, "Error " + res.data.ErrorCode);
            //     this.response = res.data;
            //   }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
